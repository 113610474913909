import React from "react";
import styled from "styled-components";
import {Link} from "gatsby";

const StyledNavigation = styled.nav`
  font-size: 0.9rem; 
  margin-bottom: 1rem;
`;

const NavigationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media only screen and (min-width: 1025px ) {
    max-width: 1080px;
    margin: 0 auto;
    flex-wrap: nowrap;
  }
`;

const NavigationColumn = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--gold-highlight-color);
  width: 50%;
  padding:0 1rem;
  white-space:pre-wrap;
  margin-bottom: 30px;
  
  h2{
    margin-bottom: 15px;
    font-weight: normal;
  }

  @media only screen and (min-width: 801px ) {
    align-items: center;
  }
`;



const StyledNavList = styled.ul`
 
  list-style: none;
  
  li:not(:last-child){
    margin-bottom: 10px;    
  }
  
  a{
    color:var(--primary-text-color);
    
  }
  
`;



const BottomNavigation = () =>{
    return <StyledNavigation>
        <NavigationContainer>
            <NavigationColumn>
                <h2>Navigation</h2>
                <StyledNavList>
                    <li><Link aria-label="Home Page" to="/">Home</Link></li>
                    <li><Link aria-label="Feature Section" to="#feature-section">Features</Link></li>
                    <li><Link aria-label="Download Section" to="#download-section">Download</Link></li>
                    <li><Link aria-label="Blog Page" to="/blog/">Blog</Link></li>
                </StyledNavList>
            </NavigationColumn>
            <NavigationColumn>
                <h2>Legal</h2>
                <StyledNavList>
                    <li><a aria-label="Terms Of Service" href="https://www.iubenda.com/terms-and-conditions/65998909">Terms of Service</a></li>
                    <li><a aria-label="Privacy Policy" href="https://www.iubenda.com/privacy-policy/65998909">Privacy Policy</a></li>
                </StyledNavList>
            </NavigationColumn>
            <NavigationColumn>
                <h2>Contact</h2>
                <StyledNavList>
                    <li><a aria-label="Compose Mail" href="mailto: support@goingshoppingapp.com">support@goingshoppingapp.com</a> </li>
                </StyledNavList>
            </NavigationColumn>
        </NavigationContainer>
    </StyledNavigation>
}

export default BottomNavigation;