import React from "react";
import Footer from "./Footer";
import BottomNavigation from "./BottomNavigation";
import styled from "styled-components";

const BottomSectionContainer = styled.div`
  border-top: 1px solid #484848;
  padding: 2rem 1rem 0 1rem;
  margin-top: 40px;
`;

const BottomSection = () =>{
    return <BottomSectionContainer>
        <BottomNavigation/>
        <Footer/>
    </BottomSectionContainer>
}

export default BottomSection;